'use strict';

import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global varaible. In ES6, all imports are hoisted
// to the top of the file so if we used`import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.

window.jQuery = $;

// require('./lib/viewport'); // Voeg een class toe als element in beeld is (optioneel).

//require('foundation-sites');
require('lazyload');
//require('mmenu-js');
require('@fancyapps/fancybox');
require('@chenfengyuan/datepicker');
require('slick-carousel');
require('forcefeed');
require('bxslider/dist/jquery.bxslider.js');

// LANGUAGE
var language = $('html').attr('lang');

var raadhuis = require('./lib/default'); // Standaard JS functions (Slick slider, mmenu, sticky nav etc.).

const bodymovin = require("lottie-web");

// If you want to pick and choose which modules to include, comment out the above and uncomment the line below, go to the file and comment :
import './lib/foundation-explicit-pieces';

import Plyr from 'plyr';

$(document).ready(function () {

  raadhuis.forceFeed();
  window.addEventListener('CookiebotOnAccept', function (e) {
    if (Cookiebot.consent.marketing) {
      const player = new Plyr('.player');
    }
  }, false);



  var hostnames = [
    'kaapskilnl.test',
    'kaapskilnl.web1.acc.raadhuis.com',
    'kaapskilnl.web1.prod.raadhuis.com',
    'kaapskil.nl'
  ];

  $('body').removeClass('preload');
  // LOADING
  setTimeout(function () {
    $('.loading').fadeOut();
  }, 100);


  // NAV
  $('#menu').on('click', function (e) {
    e.preventDefault();
    $('body').removeClass('search-open');
    $('body').toggleClass('menu-open');
  });

  $('#search').on('click', function (e) {
    e.preventDefault();
    $('body').removeClass('menu-open');
    $('body').toggleClass('search-open');
    setTimeout(function () {
      $('.search-extended input[type="text"]').focus();
    }, 1500);
  });

  $(".fancybox").fancybox({
    type: "iframe",
    beforeShow: function () {

      this.title = $(this.element).data("caption");
    }
    // other API options
  });

  $(".fancybox-image-pop").fancybox();

  $('fancybox-caption__body').on('click', function (e) {
    alert('hallo');
    $.fn.fancybox.close()
  });

  $('.gallery-image-holder').each(function () {
    var thispanel = $(this);
    var ne = thispanel.children().length;
    if (ne == 2) { //at least one child
      thispanel.addClass('second-img');
    }
    if (ne == 3) { //at least one child
      thispanel.addClass('thirth-img');
    }
    if (ne == 4) { //at least one child
      thispanel.addClass('four-img');
    }
  });


  $('.language-button').on('click', function (e) {
    $(this).toggleClass('language-active');
    toggleAriaExpanded(this);
  });

  $('.language-selector a.active').on('click', function (e) {
    e.preventDefault();
    toggleAriaExpanded(this);
  });

  $('#close').on('click', function (e) {
    e.preventDefault();
    $('body').removeClass('search-open');
  });

  $('.wrapper').on('click', function () {
    $('body').removeClass('menu-open search-open');
  });

  // REVEAL
  function revealBlock() {
    $('.animate').each(function (e) {
      $(this).addClass('show');
      if ($(this).attr('data-image')) {
        var image = $(this).attr('data-image');
        $(this).find('.image').css('background-image', 'url(' + image + ')');
      }
    });
  }
  revealBlock();
  scrollTimeline();

  // VIDEO

  $('.video').parent().on('click', function (e) {
    e.preventDefault();
    if ($(this).children(".video").get(0).paused) {
      $(this).children(".video").attr("controls", "1");
      $(this).children(".video").get(0).play();
      $(this).children(".playpause").fadeOut();
      dataLayer.push({
        'event': 'video-homepage'
      });
    } else {
      $(this).children(".video").removeAttr("controls");
      $(this).children(".video").get(0).pause();
      $(this).children(".playpause").fadeIn();
    }
  });

  // SUBMENU
  if ($('.submenu').length > 0) {
    if ($('.submenu.desktop').length) {
      var submenuOffset = $('.submenu.desktop').offset().top - 300;
    }
    if ($('.follow').length) {
      var followOffset = $('.follow').offset().top - 683;
    }


    $(window).on('scroll', function () {
      scrollTop = $(window).scrollTop();

      // Fixed submenu if collection items is more than one
      if ($('.collection-item').length > 1) {
        if (scrollTop >= submenuOffset && scrollTop < followOffset) {
          $('.submenu.desktop').addClass('fixed').removeClass('stop');
        } else if ((scrollTop >= submenuOffset) === false) {
          $('.submenu.desktop').removeClass('fixed stop').addClass('top');
        } else {
          $('.submenu.desktop').removeClass('fixed').addClass('stop');
        }
      } else {
        $('.submenu.desktop li:first-child a').addClass('active');
      }

      // Set active classes based on position
      $('.collection-item').each(function (e) {
        var index = e + 1;
        var indexMargin = (e + 1) * 32;
        var titleHeight = $(this).find('.collection-title').height();
        var offsetTop = ($(this).offset().top - 257) + titleHeight - indexMargin;
        var collectionItemHeight = $(this).outerHeight();

        if (scrollTop < offsetTop + collectionItemHeight && offsetTop < scrollTop) {
          $('.collection-item').removeClass('active');
          $(this).addClass('active');
          $('.submenu.desktop li a').removeClass('active');
          $('.submenu.desktop li:nth-child(' + index + ') a').addClass('active');
        } else {
          $(this).removeClass('active');
        }
      });
    });

    // Submenu desktop
    $('.submenu.desktop a').on('click', function (e) {
      e.preventDefault();
      var target = $(this).attr('href');
      var titleHeight = $(target).find('.collection-title').height();

      var clicked = $(this).offset().top; // Submenu link clicked
      var submenuOffset = $('.submenu.desktop').offset().top;
      var difference = clicked - submenuOffset;

      $('html, body').animate({
        scrollTop: ($(target).offset().top - 287) + titleHeight - difference
      }, 2000);
    });

    // Submenu mobile select
    $('.submenu.mobile select').on('change', function () {
      var target = $(this).val();
      $('html, body').animate({
        scrollTop: $(target).offset().top - 200
      }, 2000);
    });
  }

  // SLIDER
  $('.header-slider ')
    .on('init', function (event, slick, direction) {
      // console.log('afterChange/init', event, slick, slick.$slides);
      // remove all prev/next
      slick.$slides.removeClass('prev').removeClass('next');
      $('.slick-active').addClass('open');

      var total = slick.$slides.length - 1;
      $('.slick-active').next().addClass('next');
      $('.slick-slide[data-slick-index="' + total + '"]').addClass('prev');


    })
    .on('beforeChange', function (event, slick, currentSlide, nextSlide) {
      // optional, but cleaner maybe
      // remove all prev/next
      slick.$slides.removeClass('prev').removeClass('next').removeClass('open');

      var total = slick.$slides.length - 1;
      var prev = nextSlide - 1;
      var next = nextSlide + 1;
      if (nextSlide == 0) {
        prev = total;
      }
      if (nextSlide == total) {
        next = 0;
      }

      $('.slick-slide[data-slick-index="' + prev + '"]').addClass('prev');
      $('.slick-slide[data-slick-index="' + next + '"]').addClass('next');
    })
    .on('afterChange', function (event, slick, currentSlide, nextSlide) {
      $('.slick-active').addClass('open');
    })
    .slick({
      dots: true,
      speed: 900,
      infinite: true,
      cssEase: "cubic-bezier(0.165, 0.840, 0.440, 1.000)",
      slidesToShow: 1,
      controls: true
    });


  // TIMELINE
  var scrollPeriods;
  var scrollYear;
  var scrollTop;
  var scrollRatio;

  $('.backgrounds .background').first().addClass('active');
  $('.periods li').first().addClass('active');
  $('.years li').first().addClass('active');

  $('.highlight[data-period="period1"]').wrapAll("<div class='period' data-anchor='period1' data-period='period1'></div>");
  $('.highlight[data-period="period2"]').wrapAll("<div class='period' data-anchor='period2' data-period='period2'></div>");
  $('.highlight[data-period="period3"]').wrapAll("<div class='period' data-anchor='period3' data-period='period3'></div>");
  $('.highlight[data-period="period4"]').wrapAll("<div class='period' data-anchor='period4' data-period='period4'></div>");

  $('.periods a').on('click', function (e) {
    e.preventDefault();
    var periodID = $(this).closest('li').attr('data-anchor');

    var periodAnchor = $('.period[data-period="' + periodID + '"]').position().top + 10;
    $("html,body").animate({
      scrollTop: periodAnchor
    }, 2000);
  });

  $('.years a').on('click', function (e) {
    e.preventDefault();
    var yearID = $(this).closest('li').attr('data-year');

    var yearAnchor = $('.highlight[data-year="' + yearID + '"]').position().top + 10;
    $("html,body").animate({
      scrollTop: yearAnchor
    }, 2000);
  });

  // DATEPICKER AGENDA
  if (language === 'nl') {
    $('[data-toggle="datepicker"]').datepicker({
      language: 'nl-NL',
      format: 'dd-mm-yyyy'
    });
  }

  if (language === 'en') {
    $('[data-toggle="datepicker"]').datepicker({
      language: 'en-GB',
      format: 'dd-mm-yyyy'
    });
  }

  if (language === 'de') {
    $('[data-toggle="datepicker"]').datepicker({
      language: 'de-DE',
      format: 'dd-mm-yyyy'
    });
  }

  $('.checkstart').on('pick.datepicker', function (e) {
    console.log('hide start');
    var jsDate = $(this).datepicker('getDate');
    if (jsDate !== null) { // if any date selected in datepicker
      jsDate instanceof Date; // -> true
      var day = jsDate.getDate();
      var month = jsDate.getMonth() + 1;
      var year = jsDate.getFullYear();
    }
    $('#startunix').val(day + '-' + month + '-' + year);
  });

  $('.checkend').on('pick.datepicker', function (e) {
    console.log('hide end');
    var jsDate = $(this).datepicker('getDate');
    if (jsDate !== null) { // if any date selected in datepicker
      jsDate instanceof Date; // -> true
      var day = jsDate.getDate();
      var month = jsDate.getMonth() + 1;
      var year = jsDate.getFullYear();
    }
    $('#endunix').val(day + '-' + month + '-' + year);
  });

  $(".header-slider-wrapper").slick({
    // normal options...
    infinite: true,
    dots: true,
    arrows: true,
    slidesToShow: 1,
  });


  $(window).on('scroll', function () {
    scrollTop = $(window).scrollTop();
    scrollYear = scrollTop * 0.30476;
    scrollPeriods = scrollTop * 0.034;
    $('.years').css('transform', 'translateY(-' + scrollYear + 'px)');

    revealBlock();
    scrollTimeline();
  });

  function scrollTimeline() {
    scrollTop = $(window).scrollTop();

    if (scrollTop > 10) {
      $('body').addClass('scrolled');
    } else {
      $('body').removeClass('scrolled');
    }

    $('.highlight').each(function (e) {
      var anchor = $(this).data('anchor');
      var period = $(this).data('period');
      var offsetTop = $(this).offset().top - $(".highlights").offset().top + 60;

      if (scrollTop < offsetTop + 420 && offsetTop < scrollTop) {
        $('.highlight').removeClass('active');
        $(this).addClass('active');
        $('.years li').removeClass('active');
        $('.years li[data-anchor="' + anchor + '"]').addClass('active');

        $('.periods li').removeClass('active');
        $('.periods li[data-anchor="' + period + '"]').addClass('active');

        $('.background').removeClass('active');
        $('.background[data-period="' + period + '"]').addClass('active');

      } else {
        $(this).removeClass('active');
      }

      if ($(window).width() > 767) {
        if (offsetTop < (scrollTop + $(window).height() - 600)) {
          $(this).addClass('show');
          if ($(this).attr('data-image')) {
            var image = $(this).attr('data-image');
            $(this).find('.highlight-image').css('background-image', 'url(' + image + ')');
          }
        }
      } else if ($(window).width() < 768) {
        if (offsetTop < (scrollTop + $(window).height())) {
          $(this).addClass('show');
          if ($(this).attr('data-image')) {
            var image = $(this).attr('data-image');
            $(this).find('.highlight-image').css('background-image', 'url(' + image + ')');
          }
        }
      }

      /*if (offsetTop < (scrollTop + $(window).height())) {
      	var scrollTitle = (scrollTop - (offsetTop - 300)) * 0.2;
      	$(this).find('.highlight-content').css('transform', 'translateY(-' + scrollTitle + 'px)');
      } */
    });

    $('.period').each(function () {
      var projectID = $(this).attr('data-period');
      var offsetTop = $(this).offset().top - $(".highlights").offset().top;
      var totalPeriods = $('.period').length;

      if (scrollTop < offsetTop + $(this).height() && offsetTop < scrollTop) {
        $('.period').removeClass('active');
        $(this).addClass('active');
      } else {
        $(this).removeClass('active');
      }

      if ($('.period').hasClass('active')) {
        var periodIndex = $('.period.active').index();
        var periodScroll = scrollTop - ($('.period.active').offset().top - $(".highlights").offset().top);
        scrollRatio = 58 / ($('.period.active').find('.highlight').length * 420);
        scrollPeriods = (periodIndex * 58) + (periodScroll * scrollRatio);
        $('.periods').css('transform', 'translateY(-' + scrollPeriods + 'px)');
      }
    });
  }

  // HIGHLIGHT
  $('.highlight').each(function (e) {
    if (e % 2 === 0) {
      $(this).addClass('left');
    } else {
      $(this).addClass('right');
      $(this).after('<div style="display: block; clear: both"></div>');
    }
  });

  // SELECT
  $('.select').on('change', function () {
    var target = $('.period[data-anchor="' + $(this).val() + '"]').offset().top;
    $('html, body').animate({
      scrollTop: target
    }, 2000);
  });

  // SCROLL OMLAAG
  $('.scroll-text').on('click touch', function (e) {
    e.preventDefault();
    var target = $('.wrapper').offset().top;
    $('html, body').animate({
      scrollTop: target
    }, 2000);
  });

  // SCROLL OMLAAG
  $('.read-more').on('click touch', function (e) {
    e.preventDefault();
    $('html, body').animate({
      scrollTop: $(window).height()
    }, 2000);
  });



  var diver = bodymovin.loadAnimation({
    container: document.getElementById('diver'),
    renderer: 'svg',
    loop: true,
    autoplay: true,
    path: path + 'diver' + '.json'
  });

  diver.addEventListener('DOMLoaded', function (e) {
    diver.playSegments([0, 87], true); // loop
  });

  // Control diver
  var root = $('body');
  var lastScrollTop = 0;
  var headerOffset = 0;
  var headerScrollOffset = 30;

  if ($('.ervaringen').length) {
    var steunOffset = $(document).height() - $('.ervaringen').offset().top;
  }

  $(window).on('scroll', function () {
    var pageTop = $(document).scrollTop();

    // Slide out navbar
    if (pageTop > lastScrollTop) {
      if (pageTop > headerScrollOffset) {
        root.addClass('scrolldown');
        root.removeClass('scrollup');
        root.removeClass('on-top');
      }
    } else {

      if (pageTop > headerScrollOffset) {
        root.addClass('scrollup');
        root.removeClass('scrolldown');
        root.removeClass('on-top');
      }
    }

    if (pageTop == headerOffset) {
      root.removeClass('scrolldown');
      root.removeClass('scrollup');
      root.addClass('on-top');
    }

    lastScrollTop = pageTop;
    if ($('.ervaringen').length) {
      if ($('.ervaringen').offset().top < $(window).scrollTop() + $(window).height() + 139) {
        $('body').addClass('at-bottom');
        $('#diver').css('bottom', steunOffset + 200);
        /*
          diver.playSegments([88, 155], false); 
          diver.addEventListener("enterFrame", (e) => {
            console.log(e);
            if (e.frame === '66'){
              diver.stop();
            }           
          })
        */
      } else {
        $('body').removeClass('at-bottom');
        $('#diver').css('bottom', '');
      }
    }
  });



  // Toggle Aria Expanded
  function toggleAriaExpanded(elem) {
    if ($(elem).attr('aria-expanded') === 'true') {
      $(elem).attr('aria-expanded', 'false');
    } else {
      $(elem).attr('aria-expanded', 'true');
    }
  }

  function parallax() {
    var scrolltop = $(window).scrollTop() / 10;
    $('.bg-animation img').css({
      'top': scrolltop
    });
  }

  function scrollBlur() {
    scrollTop = $(window).scrollTop();


    $('.block.blur').each(function (e) {
      var offsetTop = $(this).offset().top;
      var blurValue = scrollTop + ($(window).height() / 2) - offsetTop;

      $('.block.blur').css('filter', 'blur(' + blurValue + 'px)');

    });
  }

  parallax();

  $(window).on('scroll', function () {
    parallax();
  });


	// REVIEW BLOCK

	var reviewSliderEl = $('.review-slider');
	var reviewSlider = reviewSliderEl.bxSlider({
		pagerCustom: '.gallery-block-pager',
		touchEnabled: false,
		responsive: true,
		auto: true,
		pause: 8000,
		maxSlides: 1,
		prevText: '<i class="fa fa-caret-left"></i>',
		nextText: '<i class="fa fa-caret-right"></i>',
		hideControlOnEnd: true,
		preventDefaultSwipeX: true
	});

	$(window).on('resize', function(){
		reviewSlider.reloadSlider();
	})


  // INIT
  scrollTimeline();
  setTimeout(function () {
    revealBlock();
  }, 300);

});

$(document).foundation();