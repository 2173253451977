var $ = jQuery;

module.exports = {
    // SLIDER
    slickSlider: function() {
        $(".slider").slick({
            // normal options...
            infinite: true,
            dots: true,
            slidesToShow: 3,
            responsive: [{
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    infinite: true
                }
            }, {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    dots: true
                }
            }, {
                breakpoint: 300,
                settings: "unslick" // destroys slick
            }]
        });
    },

    // MMENU
    mmenu: function() {
        new Mmenu("#menu", {
            // options
        }, {
            // configuration
            offCanvas: {
                page: {
                    selector: "#wrapper"
                },
                clone: true
            }
        });
    },

    // FAQ
    faq: function() {
        $('.faq-question').click(function() {
            if ($(this).closest('.faq-item').hasClass('active')) {
                $(this).closest('.faq-item').removeClass('active');
                $(this).next('.faq-answer').slideUp();
            } else {
                $('.faq-item').removeClass('active');
                $('.faq-answer').slideUp();
                $(this).closest('.faq-item').addClass('active');
                $(this).next('.faq-answer').slideDown();
            }
        });
    },

    // SCROLL TO ANCHOR
    scrollToAnchor: function(element) {
        $(element).click(function(e) {
            e.preventDefault();

            var href = $(this).attr('href');

            $('html, body').animate({
                scrollTop: $(href).offset().top - 100
            }, 600);
        });
    },

    // SCROLLED
    scrolled: function() {
        if ($(window).scrollTop() > 0) {
            $('body').addClass('scrolled');
        } else {
            $('body').removeClass('scrolled');
        }
    },

    // FILTER ON HASHCHANGE
    filterOnHashchange: function() {
        $(window).on("hashchange", function() {
            var hash = location.hash.slice(1);

            $("div[data-filter]").each(function() {
                var filter = this.getAttribute("data-filter");
                $(this).toggle(filter === hash || !hash);
            });

            $(".sort a:first-child").on("click", function() {
                $(".sort a").removeClass("active");
                $(this).addClass("active");
            });

            $(".sort a").not(".sort a:first-child").on("click", function() {
                $(".sort a").removeClass("active");
                $(this).addClass("active");
            });

            var hash = window.location.hash;

            $(".sort a").not(".sort a:first-child").removeClass("active");
            $('.sort a[href="' + hash + '"]').addClass("active");
        }).trigger("hashchange");
    },

    forceFeed: function(){
        window.words = ['lorem', 'ipsum', 'curabitur', 'consequat', 'amet', 'adipsing', 'dolor', 'pellentesque', 'sed', 'commodo', 'eu', 'ligula', 'praetermittentium', 'mollis', 'praetervolavit', 'discessimus', 'naturas', 'naturas', 'est', 'consequebatur', 'non'];

        window.sentences = ['Lorem ipsum dolor sit amet, consectetur adipiscing elit.', 'Est tamen ea secundum naturam multoque nos ad se expetendam magis hortatur quam superiora omnia.', 'Phasellus bibendum neque eros, vel malesuada ligula fermentum et.', 'Vivamus pretium vulputate imperdiet.', 'Morbi eleifend urna ac purus auctor, non porttitor massa semper.', 'Ut tempor ante et mauris sagittis, sed vestibulum urna rhoncus.', 'Mauris quis augue fermentum, auctor metus quis, imperdiet lectus.', 'Morbi nec rhoncus lectus.',  'Nulla cursus venenatis urna maximus bibendum.', 'Cras at ornare tortor.', 'Nulla nulla justo, posuere id ultricies non, feugiat et nisi.', 'Duis porttitor aliquet viverra.', 'Integer vehicula fringilla velit, at iaculis dolor imperdiet vel.', 'Eorum enim omnium multa praetermittentium', ''];
        
        forceFeed({words: window.words, sentences: window.sentences});
    }
}
